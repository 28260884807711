<template>
    <modal ref="modalAnadirProducto" titulo="Añadir producto" tamano="modal-lg" no-aceptar adicional="Añadir" @adicional="asignar">
        <cargando v-if="cargando" />
        <div class="row mx-0 py-3" />
        <div class="row mx-0 mb-4 align-items-center">
            <div class="col-1 text-center px-0">
                <el-checkbox v-model="checked" class="check-green" @change="seleccionarTodos($event)" />
            </div>
            <div class="col text-general f-600 f-17">
                Producto
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div class="col-5">
                        <p v-if="tipo_promocion == 4 || tipo_promocion == 2" class="text-general f-17 f-600">Descuento</p>
                        <template v-if="tipo_promocion == 4">
                            <ValidationObserver ref="validacion">
                                <ValidationProvider v-slot="{errors}" :rules="`required|max_value:100`" name="descuento">
                                    <el-input v-model="descuento" maxlength="8" placeholder="%" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </ValidationObserver>
                        </template>
                        <template v-if="tipo_promocion == 2">
                            <ValidationObserver ref="validacion2">
                                <ValidationProvider v-slot="{errors}" :rules="`required`" name="descuento">
                                    <money v-model="descuento" class="input-money" size="small" placeholder="$" v-bind="money" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </ValidationObserver>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(item,index) in productosAsignar" :key="index" class="row mx-0 align-items-center mb-3">
            <div class="col-1 text-center">
                <el-checkbox v-model="item.checked" class="check-green" @change="seleccionarProducto($event, item.id)" />
            </div>
            <div class="col">
                <div class="row mx-0">
                    <img :src="item.foto" class="obj-cover" width="89" height="89" />
                    <div class="col-4 px-2">
                        <p class="text-general f-14 nombre">
                            {{ item.nombre }}  
                        </p>
                        <p class="text-general2 f-14">
                            {{ item.presentacion }}
                        </p>
                        <div class="row mx-0 align-items-center">
                            <p class="col-auto px-0 f-500 text-general f-14">
                                <span class="f-600 text-general">{{ item.promo_valor=== null ? separadorNumero(item.valor, item.decimales): separadorNumero(item.promo_valor) }}</span>
                                <strike v-if="item.tipo === 2 ||item.tipo === 3 || item.tipo === 4" class="ml-2 f-12">
                                    {{ separadorNumero(item.valor, item.decimales) }}
                                </strike>
                            </p>
                            <div class="mn-pill f-12 bg-general3 text-white ml-auto">
                                {{ agregarSeparadoresNumero(item.cant_stock,item.cantidad_tipo == 2 ? 2 : 0) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones_cedis_admin'
import {Money} from 'v-money'
export default {
    components:{
        Money,
    },
    data(){
        return {
            id_cedi: parseInt(this.$route.params.id_cedis),
            cargando: false,
            checked: false,
            productosAsignar: [],
            productosSeleccionados: [],
            descuento: '',
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },
    computed: {
        ...mapGetters({
            id_promocion: 'promociones/promociones_cedis/id_promocion',
            tipo_promocion: 'promociones/promociones_cedis/tipo_promocion',
        }),
    },
    methods: {
        async asignar(){
            try {
                if(this.productosSeleccionados.length < 1)return this.notificacion('Alerta','Usted no ha seleccionado producto(s)','warning');
                if(!this.id_cedi)return;
                let form = {
                    productos: this.productosSeleccionados,
                    id_promocion: this.id_promocion,
                    tipo : this.tipo_promocion
                };
                if(this.tipo_promocion == 2){
                    const valid = await this.$refs.validacion2.validate()
                    if(!valid)return
                    if(parseInt(this.descuento) < 1)return this.notificacion('Alerta','Digite un valor para el descuento','warning');
                    form.promo_valor= this.descuento;
                }
                if(this.tipo_promocion == 4){
                    const valid = await this.$refs.validacion.validate()
                    if(!valid)return
                    if(parseInt(this.descuento) < 1)return this.notificacion('Alerta','Digite un porcentaje para el descuento','warning');
                    form.descuento = this.descuento;
                }
                this.cargando = true;
                const {data} = await Promocion.putDescuentoProductos(this.id_cedi, form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar');
                this.$refs.modalAnadirProducto.toggle();
            } catch($e){
                this.error_catch($e);
            } finally {
                this.cargando = false;
            }
        },
        seleccionarTodos(event){
            this.cargando = true;
            let cantidad = this.productosAsignar.length;
            if(cantidad < 1)return;
            if(event){
                for(let i = 0; i < cantidad; i++){
                    this.productosAsignar[i].checked = true;
                    this.productosSeleccionados.push(this.productosAsignar[i].id);
                }
                return this.cargando = false;
            }
            for(let i = 0; i < cantidad; i++){
                this.productosAsignar[i].checked = false;
            }
            this.productosSeleccionados = [];
            this.cargando = false;
        },
        seleccionarProducto(event,id){
            if(event){
                this.productosSeleccionados.push(id);
            } else {
                let index = this.productosSeleccionados.indexOf(id);
                this.productosSeleccionados.splice(index, 1);
            }
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        limpiar(){
            this.productosAsignar = [];
            this.productosSeleccionados = [];
            this.descuento = 0;
            this.checked = false;
        },
        toggle(productos){
            this.limpiar();
            this.productosAsignar = _.cloneDeep(productos);
            this.$refs.modalAnadirProducto.toggle();
        },
    }
}
</script>